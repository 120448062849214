import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@layouts';
import { SEO, TAIKWebinars } from '@components';

const Index = ({ data }) => (
  <Layout bannerHeight='700px' bannerStyle={{ backgroundImage: 'none' }} hide>
    <SEO title='TAIK WEBINARS' description='Listen to TAIK Webinars' />
    <TAIKWebinars data={data} />
  </Layout>
);

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { content_type: { in: ["webinar", "webinar-latest-videos"] } } }
      sort: { fields: [frontmatter___date, frontmatter___title], order: [DESC, ASC] }
    ) {
      edges {
        node {
          frontmatter {
            content_type
            slug
            date
            title
            excerpt
            video_url
            category
            tag
            image_url {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          html
        }
      }
    }
  }
`;

export default Index;
